<template>
  <div class="hyperlink_box">
    <div class="title">快捷入口</div>
    <div class="icon-box">
      <div class="icon-item" v-for="(item,index) in iconList" :key="index" @click="routeTo(item)">
        <img class="icon" :src="setImage(item.icon)"/>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      iconList: [
        {icon: "invoice2.png", name: "待开发票", url: "/open/issue"},
        {icon: "invoiceOpen.png", name: "手工开票", url: "/open/manual"},
        {icon: "invoiceScan.png", name: "扫码开票", url: "/open/quick-invoice"},
        {icon: "redInv.png", name: "红票开具", url: "/open/red-invoice"},
        {icon: "elecAccount.png", name: "数电账户", url: "/rpa/auth"},
        {icon: "myInvoice.png", name: "我的票夹", url: "/collect/mylist"},
        {icon: "enterpriseInv.png", name: "企业票池", url: "/collect/enterpriselist"},
        // {icon: "invoice.png", name: "发票开具", url: "/open/issue"},
        // {icon: "invoice.png", name: "发票开具", url: "/open/issue"},
        // {icon: "invoice.png", name: "发票开具", url: "/open/issue"},
      ]
    }
  },
  methods: {
    routeTo(item) {
      this.$router.push(item.url)
    },
    setImage(icon) {
      let url = ""
      try {
        url = require("./icon/" + icon)
        return url
      } catch (e) {
        return ""
      }
    }
  }
}
</script>
<style scoped lang="scss">
.hyperlink_box {
  background: #fff;
  padding: 20px;
  box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
  margin: 24px 0;

  .title {
    display: flex;
    align-items: center;

    &:before {
      content: " ";
      display: inline-block;
      font-size: 16px;
      margin-right: 8px;
      width: 4px;
      height: 20px;
      background-color: #1690FF;
    }
  }

  .icon-box {
    display: flex;
    justify-content: start;

    .icon-item {
      margin: 10px 10px 10px 30px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: rgba(100, 100, 100, 0.1);
      }

      .icon {
        height: 60px;
        width: 60px;
      }

      .name {
        font-size: 14px;
        color: #090909;
        width: 60px;
        height: 20px;
        text-align: center;
      }
    }
  }
}
</style>
