<template>
  <div class="billing_statics_view">
    <hyperlink></hyperlink>
    <div class="top_box">
      <div class="top">
        <!--        <div class="tit">开票统计</div>-->
        <div class="r_box">
          <label class="lab">当前组织</label>
          <el-select v-model="orgName" placeholder="请选择">
            <el-option v-for="item in orgList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="time_btn_list">
        <div class="time_btn_item" :class="curTimeBtn == item.value ? 'active' : ''" v-for="item in timeBtnList" :key="item.value" @click="changeTimeType(item)">{{ item.label }}</div>
      </div>
      <div class="date_box">
        <el-date-picker v-if="isShowDatePicker" v-model="curTime" :type="datePickerType" placeholder="请选择时间" :value-format="datePickerValFormat" :format="datePickerFormat" style="min-width: 200px"> </el-date-picker>
        <el-cascader v-if="curTimeBtn == 'QUARTER'" v-model="curQuarter" :options="quarterList" @change="handleChange" style="min-width: 200px; line-height: 32px; height: 32px"></el-cascader>
        <el-date-picker v-if="curTimeBtn == 'CUSTOM'" v-model="curRangeTime" type="daterange" style="min-width: 200px; line-height: 32px; height: 32px" :value-format="datePickerValFormat" :format="datePickerFormat" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </div>

      <div class="sum_total_list">
        <div class="sum_total_item">
          <div class="tit">蓝票金额（含税金额）</div>
          <div class="amount">¥{{ billingStaticData.blueSumAmount }}</div>
          <div class="percent t" v-if="billingStaticData.blueInvoicedpercentage > 0">{{ billingStaticData.blueInvoicedpercentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="billingStaticData.blueInvoicedpercentage < 0">{{ billingStaticData.blueInvoicedpercentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ billingStaticData.blueInvoicedpercentage }}</div>
        </div>
        <div class="sum_total_item">
          <div class="tit">蓝票数量（张）</div>
          <div class="amount">{{ billingStaticData.blueInvoiced }}</div>
          <div class="percent t" v-if="billingStaticData.blueSumAmountpercentage > 0">{{ billingStaticData.blueSumAmountpercentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="billingStaticData.blueSumAmountpercentage < 0">{{ billingStaticData.blueSumAmountpercentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ billingStaticData.blueSumAmountpercentage }}</div>
        </div>
        <div class="sum_total_item">
          <div class="tit">红票金额</div>
          <div class="amount">¥{{ billingStaticData.redSumAmount }}</div>
          <div class="percent t" v-if="billingStaticData.blueInvoicedpercentage > 0">{{ billingStaticData.blueInvoicedpercentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="billingStaticData.blueInvoicedpercentage < 0">{{ billingStaticData.blueInvoicedpercentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ billingStaticData.blueInvoicedpercentage }}</div>
        </div>
        <div class="sum_total_item">
          <div class="tit">红票数量（张）</div>
          <div class="amount">{{ billingStaticData.redInvoiced }}</div>
          <div class="percent t" v-if="billingStaticData.redInvoicedpercentage > 0">{{ billingStaticData.redInvoicedpercentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="billingStaticData.redInvoicedpercentage < 0">{{ billingStaticData.redInvoicedpercentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ billingStaticData.redInvoicedpercentage }}</div>
        </div>
        <div class="sum_total_item">
          <div class="tit">税额</div>
          <div class="amount">¥{{ billingStaticData.blueTaxAmount }}</div>
          <div class="percent t" v-if="billingStaticData.blueTaxAmountpercentage > 0">{{ billingStaticData.blueTaxAmountpercentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="billingStaticData.blueTaxAmountpercentage < 0">{{ billingStaticData.blueTaxAmountpercentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ billingStaticData.blueTaxAmountpercentage }}</div>
        </div>
        <div class="sum_total_item">
          <div class="tit">订单数量（张）</div>
          <div class="amount">
            <span class="amount">{{ orderStaticData.processedData }}/{{ orderStaticData.allData }}</span>
            <span class="kpl">开票率{{ orderStaticData.invoicingRate }}%</span>
          </div>
          <div class="percent t" v-if="orderStaticData.percentage > 0">{{ orderStaticData.percentage }}%<i class="el-icon-top"></i></div>
          <div class="percent b" v-else-if="orderStaticData.percentage < 0">{{ orderStaticData.percentage }}%<i class="el-icon-bottom"></i></div>
          <div class="percent" v-else>{{ orderStaticData.percentage }}</div>
        </div>
      </div>
    </div>

    <div class="center_box" v-if="curTimeBtn != 'CUSTOM'">
      <el-tabs v-model="activeName" @tab-click="handleTabsChange">
        <el-tab-pane label="开票额" name="1"></el-tab-pane>
        <el-tab-pane label="开票量" name="2"></el-tab-pane>
        <el-tab-pane label="订单量" name="3"></el-tab-pane>
      </el-tabs>
      <div class="bill_chart_box">
        <label class="chart_tit">开票趋势</label>
        <LineChart :xAxisData="chartData.categories" :seriesData="chartData.series" :legendData="legendData" :isShowYAxis="false" />
      </div>
    </div>
  </div>
</template>

<script>
import { billingStatisticSort, storeStatisticsMap, statisticsMap, billingOrder, billingStatistics } from '@/service/sal/billing-statistics.js';
import { tableStyle } from '@/util/mixins';
import LineChart from '@/components/charts/LineChart';
import hyperlink from "@/views/invoice-open/billing-statistics/hyperlink.vue"

const getFirstDayOfMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};
export default {
  mixins: [tableStyle],
  components: {
    LineChart,
    hyperlink
  },
  computed: {
    isShowDatePicker() {
      return ['DAY', 'MONTH', 'YEAR'].indexOf(this.curTimeBtn) > -1;
    }
  },
  watch: {
    rankType(nVal) {
      this.rankParams.orderBy = nVal;
      this.rankParams.page = 1;
      this._billingStatisticSort();
    },
    curRangeTime(value) {
      this.billingChartParams.beginInvoiceTime = value[0];
      this.billingChartParams.endInvoiceTime = value[1];

      this.orderChartParams.createdStart = value[0];
      this.orderChartParams.createdEnd = value[1];

      this.staticParams.beginInvoiceTime = value[0];
      this.staticParams.endInvoiceTime = value[1];

      this.orderParams.createdStart = value[0];
      this.orderParams.createdEnd = value[1];

      this.rankParams.beginInvoiceTime = value[0];
      this.rankParams.endInvoiceTime = value[1];
      this.rankParams.page = 1;
      this._initData();
    },
    curTime(nVal) {
      this.billingChartParams.dateModeValue = nVal;
      this.orderChartParams.dateModeValue = nVal;
      this.staticParams.dateModeValue = nVal;
      this.orderParams.dateModeValue = nVal;
      this.rankParams.dateModeValue = nVal;
      this.rankParams.page = 1;

      this._initData();
    },
    orgName(nVal) {
      this.billingChartParams.orgId = nVal;
      this.orderChartParams.orgId = nVal;
      this.staticParams.orgId = nVal;
      this.orderParams.orgId = nVal;
      this.rankParams.orgId = nVal;
      this.rankParams.page = 1;
      this.activeName = '1';
      this._initData();
    }
  },
  data() {
    return {
      rankType: 'BLUE_SUMAMOUNT',
      rankParams: {
        operSource: 'CYXCX',
        invoiceStatus: 'YKJ',
        beginInvoiceTime: '',
        endInvoiceTime: '',
        dateModeValue: this.$moment().format('yyyy-MM'),
        dateMode: 'MONTH',
        orderBy: 'BLUE_SUMAMOUNT',
        orderByName: '蓝票金额从高到低',
        page: 1,
        size: 15
      },
      billTotal: 0,
      billSortList: [],
      orderChartParams: {
        operSource: 'CYXCX',
        invoiceStatus: 'YKJ',
        qrcodeType: 'AZZ',
        createdStart: '',
        createdEnd: '',
        dateMode: 'MONTH', //DAY:日、MONTH:月、QUARTER:季度、YEAR:年、CUSTOM:自定义
        dateModeValue: this.$moment().format('yyyy-MM'),
        orgId: ''
      },

      orderChartData: [],
      legendOrderData: ['已处理订单(张)', '总订单(张)'],
      legendSumAmountData: ['蓝票(元)', '红票(元)', '税额(元)'],
      legendQuantitytData: ['蓝票(张)', '红票(张)'],
      legendData: [],
      invoiceQuantityData: [],
      invoiceSumAmountData: [],
      seriesData: [],
      billingChartParams: {
        operSource: 'CYXCX',
        invoiceStatus: 'YKJ',
        beginInvoiceTime: '',
        endInvoiceTime: '',
        dateMode: 'MONTH', //DAY:日、MONTH:月、QUARTER:季度、YEAR:年、CUSTOM:自定义
        dateModeValue: this.$moment().format('yyyy-MM'),
        orgId: ''
      },
      billingChartData: [],
      chartData: { series: [], categories: [] },
      orderParams: {
        qrcodeType: 'AZZ',
        operSource: 'CYXCX',
        invoiceStatus: 'YKJ',
        createdStart: '',
        createdEnd: '',
        dateModeValue: this.$moment().format('yyyy-MM'),
        dateMode: 'MONTH',
        orgId: ''
      },
      orderStaticData: {},
      billingStaticData: {},
      staticParams: {
        invoiceStatus: 'YKJ',
        beginInvoiceTime: '',
        endInvoiceTime: '',
        operSource: 'CYXCX',
        dateMode: 'MONTH', //DAY:日、MONTH:月、QUARTER:季度、YEAR:年、CUSTOM:自定义
        dateModeValue: this.$moment().format('yyyy-MM'),
        orgId: ''
      },
      rankCondList: [
        { name: '蓝票金额从高到低', type: 'BLUE_SUMAMOUNT' },
        { name: '蓝票数量从高到低', type: 'BLUE_TOTAL' },
        { name: '红票金额从高到低', type: 'RED_SUMAMOUNT' },
        { name: '红票数量从高到低', type: 'RED_TOTAL' },
        { name: '税额从高到低', type: 'BLUE_TAXAMOUNT' },
        { name: '订单数量从高到低', type: 'ORDER_TOTAL' }
      ],
      page: { current: 1, size: 15, total: 0 },
      pageSizes: [15, 30, 50, 100],
      tableData: [],
      activeName: '1',
      orgName: '全部',
      orgList: [],
      curTimeBtn: 'MONTH',
      timeBtnList: [
        { label: '按日', value: 'DAY' },
        { label: '按月', value: 'MONTH' },
        { label: '按季', value: 'QUARTER' },
        { label: '按年', value: 'YEAR' },
        { label: '自定义', value: 'CUSTOM' }
      ],
      curTime: new Date(),
      curRangeTime: [getFirstDayOfMonth(new Date()), new Date()],
      datePickerType: 'month',
      datePickerValFormat: 'yyyy-MM',
      datePickerFormat: 'yyyy年MM月',
      curQuarter: [],
      quarterList: []
    };
  },
  created() {
    this.orgList = [{ name: '全部', id: ' ' }, ...JSON.parse(localStorage.getItem('organizations'))] || [];
    this.calcQuarterData();
    this._initData();
  },
  methods: {
    _initData() {
      this._getBllingStatistics();
      this._getBillingOrder();
      this._statisticsMap();
      this._storeStatisticsMap();
      this._billingStatisticSort();
    },
    // 开票排行
    async _billingStatisticSort() {
      const {
        data: { records = [], total = 0 }
      } = await billingStatisticSort(this.rankParams);
      // records.forEach((item) => {
      //   item.isShowOrderTip = false;
      // });
      this.billSortList = records;
      this.billTotal = total;
    },

    // 金额、数量统计图
    async _statisticsMap() {
      const {
        data: { invoiceTime = [], blueQuantity = [], redQuantity = [], blueTaxAmount = [], blueSumAmount = [], redSumAmount = [] }
      } = await statisticsMap(this.billingChartParams);
      this.chartData.categories = invoiceTime;

      this.invoiceQuantityData = [
        {
          name: '蓝票(张)',
          type: 'line',
          // stack: 'Total',
          data: blueQuantity,
          itemStyle: {
            color: '#3370FF'
          },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        },
        {
          name: '红票(张)',
          type: 'line',
          // stack: 'Total',
          data: redQuantity,
          itemStyle: {
            color: '#F52F3E'
          },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        }
      ];

      this.invoiceSumAmountData = [
        {
          name: '蓝票(元)',
          type: 'line',
          // stack: 'Total',
          data: blueSumAmount,
          itemStyle: {
            color: '#3370FF'
          },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        },
        {
          name: '红票(元)',
          type: 'line',
          // stack: 'Total',
          data: redSumAmount,
          itemStyle: {
            color: '#F52F3E'
          },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        },
        {
          name: '税额(元)',
          type: 'line',
          // stack: 'Total',
          data: blueTaxAmount,
          itemStyle: {
            color: '#FAC858'
          },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        }
      ];

      this.chartData.series = this.invoiceSumAmountData || [];
      this.legendData = this.legendSumAmountData;
    },
    // 订单统计图
    async _storeStatisticsMap() {
      const {
        data: { invoiceTime = [], reqProcessedQuantity = [], reqQuantity = [] }
      } = await storeStatisticsMap(this.orderChartParams);

      this.chartData.categories = invoiceTime;
      this.orderChartData = [
        {
          name: '已处理订单(张)',
          type: 'line',
          // stack: 'Total',
          data: reqProcessedQuantity,
          itemStyle: {
            color: '#3370FF'
          },
          // markPoint: {
          //   data: [
          //     { type: 'max', name: '最大值' },
          //     { type: 'min', name: '最小值' }
          //   ]
          // },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        },
        {
          name: '总订单(张)',
          type: 'line',
          // stack: 'Total',
          data: reqQuantity,
          itemStyle: {
            color: '#F52F3E'
          },
          // markPoint: {
          //   data: [
          //     { type: 'max', name: '最大值' },
          //     { type: 'min', name: '最小值' }
          //   ]
          // },
          label: {
            show: true, // 显示数据值
            position: 'top' // 数据值显示在顶部
          }
        }
      ];
    },

    // 订单统计
    async _getBillingOrder() {
      const { data = {} } = await billingOrder(this.orderParams);
      this.orderStaticData = data;
    },
    // 开票统计
    async _getBllingStatistics() {
      const { data = {} } = await billingStatistics(this.staticParams);
      this.billingStaticData = data;
    },
    handleSizeChange(size) {
      this.rankParams.page = 1;
      this.rankParams.size = size;
      this._billingStatisticSort();
    },
    handleCurrentChange(page) {
      this.rankParams.page = page;
      this._billingStatisticSort();
    },
    handleTabsChange() {
      switch (this.activeName) {
        case '1':
          this.chartData.series = this.invoiceSumAmountData;
          this.legendData = this.legendSumAmountData;
          break;
        case '2':
          this.chartData.series = this.invoiceQuantityData;
          this.legendData = this.legendQuantitytData;
          break;
        case '3':
          this.chartData.series = this.orderChartData;
          this.legendData = this.legendOrderData;
          break;
        default:
          break;
      }
    },
    // 按季节
    handleChange(value) {
      this.billingChartParams.dateModeValue = `${value[0]}-${value[1]}`;
      this.orderChartParams.dateModeValue = `${value[0]}-${value[1]}`;
      this.staticParams.dateModeValue = `${value[0]}-${value[1]}`;
      this.orderParams.dateModeValue = `${value[0]}-${value[1]}`;
      this.rankParams.dateModeValue = `${value[0]}-${value[1]}`;

      this._initData();
    },
    changeTimeType({ value }) {
      if (this.curTimeBtn == value) return;
      this.curTimeBtn = value;
      this.activeName = '1';
      this.billingChartParams.dateMode = value;
      this.orderChartParams.dateMode = value;
      this.staticParams.dateMode = value;
      this.orderParams.dateMode = value;
      this.rankParams.dateMode = value;
      this.rankParams.page = 1;

      const beginTime = this.$moment().startOf('month').format('yyyy-MM-DD');
      const endTime = this.$moment().endOf('day').format('yyyy-MM-DD');
      switch (value) {
        case 'DAY':
          this.datePickerType = 'date';
          this.datePickerValFormat = 'yyyy-MM-dd';
          this.datePickerFormat = 'yyyy年MM月dd日';
          this._setDodeValue(this.$moment().format('yyyy-MM-DD'));

          break;
        case 'MONTH':
          this.datePickerType = 'month';
          this.datePickerValFormat = 'yyyy-MM';
          this.datePickerFormat = 'yyyy年MM月';
          this._setDodeValue(this.$moment().format('yyyy-MM'));

          break;
        case 'QUARTER':
          this.datePickerType = 'QUARTER';
          this._setDodeValue(`${this.curQuarter[0]}-${this.curQuarter[1]}`);

          break;
        case 'YEAR':
          this.datePickerType = 'year';
          this.datePickerValFormat = 'yyyy';
          this.datePickerFormat = 'yyyy年';
          this._setDodeValue(this.$moment().format('yyyy'));

          break;
        case 'CUSTOM':
          this.datePickerType = 'daterange';
          this.datePickerValFormat = 'yyyy-MM-dd';
          this.datePickerFormat = 'yyyy年MM月dd日';

          this.billingChartParams.beginInvoiceTime = beginTime;
          this.billingChartParams.endInvoiceTime = endTime;

          this.orderChartParams.createdStart = beginTime;
          this.orderChartParams.createdEnd = endTime;

          this.staticParams.beginInvoiceTime = beginTime;
          this.staticParams.endInvoiceTime = endTime;

          this.orderParams.createdStart = beginTime;
          this.orderParams.createdEnd = endTime;

          this.rankParams.beginInvoiceTime = beginTime;
          this.rankParams.endInvoiceTime = endTime;
          this._setDodeValue('');

          break;
        default:
          break;
      }

      this._initData();
    },
    _setDodeValue(val = '') {
      this.billingChartParams.dateModeValue = val;
      this.orderChartParams.dateModeValue = val;
      this.staticParams.dateModeValue = val;
      this.orderParams.dateModeValue = val;
      this.rankParams.dateModeValue = val;
    },
    // 计算按季度查询的数据
    calcQuarterData() {
      for (let i = 0; i < 10; i++) {
        this.quarterList.push({
          value: new Date().getFullYear() - i,
          label: `${new Date().getFullYear() - i}年`,
          children: [
            { label: '第一季度', value: 1 },
            { label: '第二季度', value: 2 },
            { label: '第三季度', value: 3 },
            { label: '第四季度', value: 4 }
          ]
        });
      }
      this.curQuarter = [this.quarterList[0].value, this.quarterList[0].children[0].value];

      console.log(this.curQuarter);
    }
  }
};
</script>

<style scoped lang="scss">
.billing_statics_view {
  .top_box {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dcdee0;
      padding-bottom: 20px;

      .tit {
        font-size: 16px;
        color: #3d3d3d;
      }
      .r_box {
        .lab {
          font-size: 14px;
          color: #333333;
          margin-right: 10px;
        }
      }
    }
    .time_btn_list {
      display: flex;
      .time_btn_item {
        color: #909399;
        font-size: 14px;
        padding: 20px 20px 20px 0;
        cursor: pointer;

        &.active {
          color: #409eff;
        }
      }
    }
    .sum_total_list {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;

      .sum_total_item {
        padding: 20px;
        background: #f5f9fe;
        font-size: 14px;
        border-radius: 10px;
        min-width: 144px;
        & + .sum_total_item {
          margin-left: 10px;
        }
        flex: 1;
        .tit {
          color: #909399;
        }
        .amount {
          font-size: 18px;
          color: #0e0f2b;
          font-weight: 600;
          margin: 10px 0;
        }
        .kpl {
          font-size: 14px;
          color: #909399;
          min-width: 150px;
          margin-left: 50px;
        }
        .el-icon-bottom,
        .b {
          color: #41b221;
        }
        .el-icon-top,
        .t {
          color: #d83116;
        }
      }
    }
    .date_box {
      line-height: 32px;
    }
  }
  .center_box {
    background: #fff;
    margin: 24px 0;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    .bill_chart_box {
      padding: 20px;
      height: 300px;
      .chart_tit {
        line-height: 24px;
        font-size: 16px;
        color: #3d3d3d;
      }
    }
  }
  .bottom_box {
    background: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    margin: 24px 0;
    padding: 20px;
    .tit_box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      .tit {
        font-weight: bold;
        font-size: 16px;
        color: #3d3d3d;
      }
    }
    .page_box {
      text-align: center;
      padding: 24px 0;
      margin: 0 auto;
    }
  }
  .el-date-editor.el-input__inner {
    min-width: 300px !important;
  }
}
</style>
