<template>
  <div class="line_chart_cmps" ref="lineChart"></div>
</template>

<script>
export default {
  props: {
    xAxisData: Array,
    seriesData: Array,
    legendData: Array,
    isShowYAxis: Boolean
  },
  watch: {
    seriesData: {
      handler() {
        this.option.legend.data = JSON.parse(JSON.stringify(this.legendData));
        this.option.xAxis.data = JSON.parse(JSON.stringify(this.xAxisData));
        this.option.series = JSON.parse(JSON.stringify(this.seriesData));

        this.myChart && this.myChart.clear(); //数据共用图表，清除数据缓存
        this.myChart && this.myChart.setOption(this.option);
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      myChart: null,
      series: [],

      option: {
        title: {
          // text: '折线图',
          textStyle: { color: 'black', fontSize: '14' } //左侧标题颜色配置
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            // animation: false,
            label: {
              color: 'transparent',
              backgroundColor: 'transparent' //轴数据背景色
            }
          }
        },
        legend: {
          data: this.legendData
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData
        },
        yAxis: {
          type: 'value',
          show: this.isShowYAxis
          // axisLabel: {
          //   formatter: (value) => {
          //     return Math.round(value);
          //   }
          // }
        },
        // grid: [{ bottom: '200px' }, { top: '10%' }],
        series: this.seriesData,

        toolbox: {
          //echart保存为图片
          show: false,
          feature: {
            mark: {
              show: true
            },
            restore: {
              show: false
            },
            saveAsImage: {
              show: false,
              pixelRatio: 1,
              title: '保存为图片',
              type: 'png',
              lang: ['点击保存']
            }
          }
        }
      }
    };
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.myChart = this.$echarts.init(this.$refs.lineChart);
      this.myChart.setOption(this.option);
      // window.addEventListener('resize', function () {
      //   this.myChart.resize();
      // });
    }
  }
};
</script>

<style scoped lang="scss">
.line_chart_cmps {
  width: 100%;
  height: 100%;
  // height: 600px;
}
</style>
