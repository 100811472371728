import { get } from '@/service/index';
// 开票统计(新)
export const billingStatistics = (data) => {
  return get('/sal/invoice/bill/statistics2', data).then((res) => res);
};
//  订单数据(新)
export const billingOrder = (data) => {
  return get('/store/request/order/homepage/data', data).then((res) => res);
};

// 已开发票统计图
export const statisticsMap = (data) => {
  return get('/sal/invoice/statistics/map', data).then((res) => res);
};
// 订单统计图(新)
export const storeStatisticsMap = (data) => {
  return get('/store/request/statistics/map', data).then((res) => res);
};
//  开票排行(新)
export const billingStatisticSort = (data) => {
  return get('/sal/invoice/bill/statistics/sort', data).then((res) => res);
};
